import React, { lazy, Suspense } from "react";
import { useState, useEffect } from "react";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
// import Biography from "./components/Biography";
// import Contact from "./components/Contact";
// import Sections from "./components/exhibitions/Sections";
// import Home from "./components/home/Home";
// import Settings from "./components/settings/Settings";
// import Paintings from "./components/exhibitions/Paintings";
// import Footer from "./components/Footer";
// import NavBar from "./components/NavBar/NavBar";
import cx from "classnames";
import "./configureAmplify.js";
// import PaintingPage from "./components/exhibitions/PaintingPage";
// import OtherSections from "./components/otherPaintings/OtherSections";
// import OtherPaintings from "./components/otherPaintings/OtherPaintings";
// import OtherPaintingPage from "./components/otherPaintings/OtherPaintingPage";

const Home = lazy(() => import("./components/home/Home"));
const Biography = lazy(() => import("./components/Biography"));
const Contact = lazy(() => import("./components/Contact"));
const Sections = lazy(() => import("./components/exhibitions/Sections"));
const Settings = lazy(() => import("./components/settings/Settings"));
const NewSettings = lazy(() => import("./components/settings/NewSettings"));
const Paintings = lazy(() => import("./components/exhibitions/Paintings"));
const Footer = lazy(() => import("./components/Footer"));
const NavBar = lazy(() => import("./components/NavBar/NavBar"));
const OtherSections = lazy(() =>
  import("./components/otherPaintings/OtherSections")
);
const OtherPaintings = lazy(() =>
  import("./components/otherPaintings/OtherPaintings")
);
const OtherPaintingPage = lazy(() =>
  import("./components/otherPaintings/OtherPaintingPage")
);
// const cx = lazy(() => import("classnames"));

const PaintingPage = lazy(() =>
  import("./components/exhibitions/PaintingPage")
);

const App = () => {
  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      document.title = "Lisa Ahronee Golub - Home";
    }
  }, [location]);

  const [toggle, setToggle] = useState(false);
  // const currentLocation = theLocation.pathname.split("/")[1];

  function toggleMenu() {
    setToggle(!toggle);
  }

  function hideMenu(toggle) {
    if (toggle) {
      setToggle(false);
    }
  }

  const classes = cx("pusher", "bottom", { dimmed: toggle });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 990);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 990;
        if (ismobile !== isMobile) setIsMobile(ismobile);
        //BUG: Zooming the page on landscape mode on tablets with width bigger than 1120 (breakpoint), causes the navmenu to break
        // if (window.devicePixelRatio >= 2) {
        //   console.log("zoom is 200%");
        //   setIsMobile(false);
        // }
      },
      false
    );
  }, [isMobile]);

  //Variable to adjust the height of the main component based on the

  return (
    <div>
      <Suspense fallback={<h1>Loading</h1>}>
        <NavBar toggleMenu={toggle} onToggleMenu={toggleMenu} />
        <div
          className="ui container attached pushable"
          style={{
            height: "80vh",
            overflow: "scroll",
            marginTop: "1vh",
            scrollbarWidth: "none",
          }}
        >
          <div className={classes} onClick={hideMenu}>
            <Route path="/" exact component={Home} />
            <Route path="/biography" exact component={Biography} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/exhibitions" exact component={Sections} />
            <Route exact path="/exhibitions/:userId" component={Paintings} />
            <Route
              exact
              path="/exhibitions/:userId/:userId"
              component={PaintingPage}
            />
            <Route path="/paintings" exact component={OtherSections} />
            <Route exact path="/paintings/:userId" component={OtherPaintings} />
            <Route
              exact
              path="/paintings/:userId/:userId"
              component={OtherPaintingPage}
            />
            <Route path="/settings" exact component={NewSettings} />

            {/* testing out speeding up the website */}

            {/* <Route path="/pictures" exact component={Sections} /> */}

            {/* Below is a test! Delete later */}
            {/* <Route path="/displayData" exact component={displayData} /> */}
            {/* Above is a test! Delete later */}
            {/* <Route path="/paintings/portraits" exact component={Portraits} />
            <Route path="/paintings/Animals" exact component={Animals} />
            <Route path="/paintings/Landscapes" exact component={Landscapes} />
            <Route path="/paintings/Readers" exact component={Readers} />
            <Route path="/paintings/StillLifes" exact component={StillLifes} /> */}
          </div>
        </div>
        <Footer />
      </Suspense>
    </div>
  );
};

export default App;
