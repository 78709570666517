import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import "./fonts/Forte/FORTE.TTF";
import "./fonts/Luzern/LUZERN.TTF"; //Cordialupc
import "./fonts/Castellar/CASTELAR.ttf"; //Castellar
import "./fonts/Papyrus/PAPYRUS.ttf"; //Papyrus

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.querySelector("#root")
);
