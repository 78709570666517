/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://xiqmlnfmfnh6pcfolkvpom2uqq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vm6mmnpeirh4xmtwarczaogcaa",
    "aws_appsync_dangerously_connect_to_http_endpoint_for_testing": true,
    "aws_cognito_identity_pool_id": "us-east-1:cfb20b13-6e19-4c23-a863-fd25c550433b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_gowWXoOGD",
    "aws_user_pools_web_client_id": "4p4oc59qbtrjggcooptba77d04",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lahrgo594ef86f650142d89ea0f6ab3e3555f1122458-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
